<template>
  <div>
    <dialog-component
      :title="'Workflow Expiration & Reminders'"
      :visible.sync="docSettingsVisible"
      width="100%"
      top="25px"
      @before-close="close"
    >
      <el-form ref="form" :model="settings" >
        <el-row class="sidemenu">
          <el-col :span="24">
            <div class="card-body">
              <div class="py-3 px-2">
                <h4 class="fs-6 text-dark fw-medium">Secure E-Sign</h4>
                <el-switch
                  :active-value="true"
                  :inactive-value="false"
                  v-model="settings.secure_e_sign.allow_document_e_sign"
                  active-text="Secure E-Sign"
                >
                </el-switch>
              </div>
              <div
                  class="mb-1"
                  v-if="
                    settings.secure_e_sign.allow_document_e_sign
                  "
                >
                  <el-checkbox-group
                    v-model="settings.secure_e_sign.notify_through"
                  >
                    <el-checkbox label="Send_Email">Send Email</el-checkbox>
                    <el-checkbox label="Send_Sms">Send Sms</el-checkbox>
                    <!-- <el-checkbox label="Send_Whatsapp">Send Whatsapp</el-checkbox>  -->
                  </el-checkbox-group>
                  <span style="color: red" v-if="sendError && !settings.secure_e_sign.notify_through.length" >
                   Please Select Atleast One Option
                   </span>
                </div>
            </div>
            <el-divider class="m-0"></el-divider>
            <div class="card-body">
              <div class="pb-3 px-2">
                <h4 class="fs-6 text-dark fw-medium">Update entity data</h4>
                <p>
                  If you used entity fields on document. You can update the
                  entity data by that document data.
                </p>
                <div class="d-flex w-100 align-items-center">
                  <el-radio-group v-model="settings.update_entity_data">
                    <el-radio :label="0"
                      >Update after document completion</el-radio
                    >
                    <el-radio :label="1"
                      >Update after user finishes the document</el-radio
                    >
                    <el-radio :label="2">Never update</el-radio>
                  </el-radio-group>
                </div>
              </div>
            </div>
             <el-divider class="m-0"></el-divider>
            <div class="card-body">
              <div class="pb-3 px-2">
                <h4 class="fs-6 text-dark fw-medium">Document Generation Settings</h4>
                <div class="d-flex align-items-center">
                  <el-checkbox
                    v-model="
                      settings.document_generation_settings.add_document_generated_id
                    "
                  >
                    Add eSigns generated id</el-checkbox
                  >
                  <el-checkbox
                    v-model="
                      settings.document_generation_settings.add_page_number
                    "
                  >
                    Add page numbers</el-checkbox
                  >
                </div>
                
              </div>
            </div>
            <el-divider class="m-0"></el-divider>
            <div class="card-body">
              <div class="pb-3 px-2">
                <h4 class="fs-6 text-dark fw-medium">Send attachment</h4>
                <div class="d-flex align-items-center">
                  <el-checkbox
                    v-model="settings.email_attachement.attach_pdf"
                  ></el-checkbox>
                  <div class="px-2 word-break-normal">
                    {{
                      "Send completed document as attachment in mail after document completed."
                    }}
                  </div>
                </div>
              </div>
            </div>
            <el-divider class="m-0"></el-divider>
            <div class="card-body">
              <div class="pb-3 px-2">
                <h4 class="fs-6 text-dark fw-medium">Expiration Settings</h4>
                <p>
                  Set the expiry date for your document validity so the system
                  does it for you automatically.
                </p>
                <div class="d-flex w-100 align-items-center">
                  <div style="max-width: 200px">
                    {{
                      getIsMobile
                        ? "Expire sent docs in"
                        : "Expire sent documents in"
                    }}
                  </div>
                  <div class="pl-2" style="max-width: 180px">
                    <el-input
                      placeholder="0"
                      min="1"
                      type="number"
                      v-on:input="changeExpireDate"
                      v-model="
                        settings.expiration_settings.expire_documents_in_days
                      "
                      size="medium"
                    >
                      <template slot="append">
                        <i class="el-icon-date"></i> days</template
                      >
                    </el-input>
                  </div>
                </div>
                <div class="expiration-reminder-check mt-1">
                  <div class="d-flex align-items-center">
                    <div class="px-2 word-break-normal">Expire Date</div>
                    <el-date-picker
                      v-model="
                        settings.expiration_settings.document_expired_date
                      "
                      disabled
                    ></el-date-picker>
                  </div>
                </div>
                <div class="expiration-reminder-check mt-1">
                  <div class="d-flex align-items-center">
                    <el-checkbox
                      v-model="
                        settings.expiration_settings
                          .send_expiration_reminder_status
                      "
                    ></el-checkbox>
                    <div class="px-2 word-break-normal">
                      {{ getIsMobile ? "Remind in" : "Send First Reminder" }}
                    </div>
                    <el-input-number
                      v-model="
                        settings.expiration_settings.send_first_reminder_in_days
                      "
                      :disabled="
                        !settings.expiration_settings
                          .send_expiration_reminder_status
                      "
                      :min="1"
                      :max="
                        settings.expiration_settings.expire_documents_in_days
                      "
                    ></el-input-number>
                    <div class="pl-1">Before Expiration</div>
                  </div>
                </div>
                <div class="expiration-reminder-check mt-1">
                  <div class="d-flex align-items-center">
                    <el-checkbox
                      v-model="
                        settings.expiration_settings
                          .repeat_expiration_reminder_status
                      "
                    ></el-checkbox>
                    <div class="px-2 word-break-normal">
                      {{ getIsMobile ? "Repeat" : "Repeat Reminder" }}
                    </div>
                    <el-input-number
                      v-model="
                        settings.expiration_settings.repeat_expiration_reminder
                      "
                      :disabled="
                        !settings.expiration_settings
                          .repeat_expiration_reminder_status
                      "
                      :min="1"
                      :max="
                        settings.expiration_settings.expire_documents_in_days
                      "
                    ></el-input-number>
                    <div class="pl-1">in Days</div>
                  </div>
                </div>
              </div>
            </div>
            <el-divider class="m-0"></el-divider>
            <div class="card-body">
              <div class="py-3 px-2">
                <h4 class="fs-6 text-dark fw-medium">
                  Auto Reminders Settings
                </h4>
                <p>
                  Automatically send email reminders to signers regarding the
                  incompletion of document.
                </p>
                <div class="d-flex w-100 align-items-center mb-1">
                  <el-checkbox
                    v-model="
                      settings.auto_remainders_settings.first_reminder_status
                    "
                  ></el-checkbox>
                  <div class="px-2 word-break-normal" style="max-width: 200px">
                    {{ getIsMobile ? "Remind in" : "Send First Reminder in" }}
                  </div>
                  <div class="pl-2" style="max-width: 180px">
                    <el-input
                      placeholder="0"
                      type="number"
                      :min="0"
                      :max="
                        settings.expiration_settings.expire_documents_in_days
                      "
                      :disabled="
                        !settings.auto_remainders_settings.first_reminder_status
                      "
                      v-model="
                        settings.auto_remainders_settings
                          .send_first_reminder_in_days
                      "
                      size="medium"
                    >
                      <template slot="append">
                        <i class="el-icon-date"></i> days</template
                      >
                    </el-input>
                  </div>
                </div>

                <div class="expiration-reminder-check">
                  <div class="d-flex align-items-center">
                    <el-checkbox
                      v-model="
                        settings.auto_remainders_settings.repeat_reminder_status
                      "
                    ></el-checkbox>
                    <div
                      class="px-2 word-break-normal"
                      style="width: 100px; padding-right: 0px !important"
                    >
                      {{ getIsMobile ? "Repeat" : "Repeat Reminder" }}
                    </div>
                    <el-input-number
                      v-model="
                        settings.auto_remainders_settings.repeat_reminder
                      "
                      :disabled="
                        !settings.auto_remainders_settings
                          .repeat_reminder_status
                      "
                      :min="1"
                      :max="
                        settings.expiration_settings.expire_documents_in_days
                      "
                    ></el-input-number>
                    <div class="pl-1">
                      {{
                        getIsMobile ? "Days" : "Until completed &amp; expired"
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-form>
        <el-divider class="m-0"></el-divider>
        <span slot="footer">
          <el-button @click="close">Cancel</el-button>
          <el-button type="primary" @click="checkUpdateSettings"
            >Save</el-button
          >
        </span>
    
    </dialog-component>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
   name:"workflows-workflowSettings",
  props: ["workflowId", "docSettingsVisible", "workflowSettings", "isEdit"],
  computed: {
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
    ...mapGetters("settings", ["getUserSettings"]),
    ...mapGetters("templateWorkflow", [
      "getWorkflowCreatedData",
      "getWorkflowDataById",
      "getWorkflowDataUpdateStatus",
      "getWorkflowCreatedDataUpdateStatus",
      "getCreatedworkflowDataById",
    ]),
  },
  data() {
    return {
      sendError: false,
        settings: {
          secure_e_sign: {
           allow_document_e_sign: false,
          notify_through: [],
          },
          auto_remainders_settings: {
            send_first_reminder_in_days: "",
            repeat_reminder: "",
            repeat_reminder_status: true,
            first_reminder_status: true,
          },
          expiration_settings: {
            send_expiration_reminder_status: true,
            repeat_expiration_reminder_status: true,
            send_first_reminder_in_days: "",
            is_default: true,
            repeat_expiration_reminder: "",
            expire_documents_in_days: 1,
            document_expired_date: "",
          },
          update_entity_data: 0,
           document_generation_settings: {
          add_document_generated_id: true,
          add_page_number: true,
        },
          email_attachement:{
            attach_pdf: true
          }
        },
      expirationRemainder: true,
      sendFirstRemainder: true,
      repeatRemainderCheck: true,
    };
  },
  mounted() {
    if (this.isEdit) {
    this.fetchWorkflowSettings();
      // this.fetchWorkflowId();
      // this.userDefaultSettings();
    } else {
      this.fetchWorkflowDataById();
    }
      this.setUserSettings(this.workflowSettings);
  },
  methods: {
    async fetchWorkflowDataById() {
      try {
        let id = this.$route.params.workflow_data_id;
        await this.$store.dispatch(
          "templateWorkflow/fetchWorkflowDataById",
          id
        );
        if (this.getCreatedworkflowDataById && this.getCreatedworkflowDataById.workflow_settings) {
          this.setUserSettings(
            this.getCreatedworkflowDataById.workflow_settings
          );
        }
      this.modalLoading = false;
      } catch (err) {
        console.log("fetchWorkflowDataById Error",err);
      }
    },
    async fetchWorkflowSettings() {
      let id = this.$route.params.workflow_id;
      await this.$store.dispatch("templateWorkflow/fetchWorkflowById", id);
      this.setUserSettings(this.getWorkflowDataById.workflow_settings);
    },
    async userDefaultSettings() {
      await this.$store.dispatch("settings/fetchUserDocumentSettings");
      this.setUserSettings();
    },
    close() {
      this.$emit("close", {});
    },
    changeExpireDate() {
      this.settings.expiration_settings.document_expired_date =
        this.$moment().add(
          this.settings.expiration_settings.expire_documents_in_days,
          "days"
        );
    },
    checkUpdateSettings(){
      if (this.isEdit) {
        this.updateDocSettingsData();
      } else {
        this.workflow_data_id = this.$route.params.workflow_data_id;
        this.updatedWorkflowDataById();
      }
    },
    async updatedWorkflowDataById(){
      let params = {
        workflow_settings: this.settings,
        id:  this.$route.params.workflow_data_id,
      };
      await this.$store.dispatch("templateWorkflow/updateWorkFlowDataById", params);
      if (this.getWorkflowCreatedDataUpdateStatus) {
        this.close();
      }
    },
    async updateDocSettingsData() {
      if(this.settings.secure_e_sign.allow_document_e_sign && this.settings.secure_e_sign &&  this.settings.secure_e_sign.notify_through.length<1  ){
          this.sendError=true;

        }else{
      let params = {
        workflow_settings: this.settings,
        id: this.$route.params.workflow_id,
      };
      await this.$store.dispatch("templateWorkflow/updateWorkFlowById", params);
      if (this.getWorkflowDataUpdateStatus) {
        this.$notify.success({
          title:"Success",
          message:"Settings Saved Successully."
        })
        this.close();
      }
    }
    },
    setUserSettings(data) {
      this.settings.expiration_settings.send_expiration_reminder_status =
        data.expiration_settings.send_expiration_reminder_status;
      this.settings.expiration_settings.repeat_expiration_reminder_status =
        data.expiration_settings.repeat_expiration_reminder_status;
      this.settings.expiration_settings.expire_documents_in_days =
        data.expiration_settings.expire_documents_in_days;
      this.settings.expiration_settings.send_first_reminder_in_days =
        data.expiration_settings.send_first_reminder_in_days;
      this.settings.expiration_settings.repeat_expiration_reminder =
        data.expiration_settings.repeat_expiration_reminder;
      this.settings.auto_remainders_settings.repeat_reminder =
        data.auto_remainders_settings.repeat_reminder;
      this.settings.auto_remainders_settings.send_first_reminder_in_days =
        data.auto_remainders_settings.send_first_reminder_in_days;
      this.settings.auto_remainders_settings.repeat_reminder_status =
        data.auto_remainders_settings.repeat_reminder_status;
      this.settings.auto_remainders_settings.first_reminder_status =
        data.auto_remainders_settings.first_reminder_status;
      this.settings.secure_e_sign.allow_document_e_sign =
        data.secure_e_sign && data.secure_e_sign.allow_document_e_sign
          ? data.secure_e_sign.allow_document_e_sign
          : false;
          this.settings.document_generation_settings.add_document_generated_id =
          data.document_generation_settings.add_document_generated_id;
           this.settings.document_generation_settings.add_page_number =
          data.document_generation_settings.add_page_number;
      this.settings.update_entity_data = data.update_entity_data ? data.update_entity_data : 0;
      this.settings.email_attachement.attach_pdf = data.email_attachement && data.email_attachement.attach_pdf ? data.email_attachement.attach_pdf : false;
      this.settings.secure_e_sign.notify_through = data.secure_e_sign.notify_through;
      this.changeExpireDate();
    },
  },
  // beforeDestroy() {
  //   this.$store.commit("settings/setUserSettings",null,{root:true})
  //   this.$store.commit("templateWorkflow/setWorkflowCreatedData",null,{root:true})
  //   this.$store.commit("templateWorkflow/setWorkflowDataById",null,{root:true})
  //   this.$store.commit("templateWorkflow/setWorkflowDataUpdateStatus",null,{root:true})
  //   this.$store.commit("templateWorkflow/setWorkflowCreatedDataUpdateStatus",null,{root:true})
  // }
};
</script>

<style scoped lang="scss">
.sidemenu{
  height: 500px;
  overflow-y: scroll;
  overflow-x: scroll;
  border-top:1px ridge;
  border-left: 1px ridge;
  border-bottom: 1px ridge;
  margin-right: 5px;
}
.sidemenu::-webkit-scrollbar {
   width: 0.7em;
}
.sidemenu::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    background: #e1e1e1;
    -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9); 
}
.sidemenu::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #F5F5F5; 
    background-color: #F5F5F5;

}
</style>